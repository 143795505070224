<template>
  <div class="flex justify-center h-screen items-center">
    <div class="border-gray-300 h-14 w-14 animate-spin rounded-full border-2 border-t-blue-600" />
  </div>
</template>

<script>


export default {
  name: 'SSO',
  data() {
    return {
      loginLoading: true,
      error: false,
    }
  },
  mounted() {
    console.log('Hello from SSO page')
    this.error = false
    this.$store.dispatch('sso/loginWithSSO', this.$route.query)
    .then((response) => {
      this.$router.push('/home')
      this.$store.commit('auth/setAuthentication', {...response.data, status: true})
      this.loginLoading = false
    })
    .catch((error) => {
      this.error = true
      this.loginLoading = false
      console.error('promise error =', error)
        this.$store.commit('sso/login')
    })
  },
  watch: {
    input: {
      deep:true
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/config/colors.scss";

.input-group-text {
  align-self: auto;
}

.pre-title {
  color: $logo;
  font-weight: 700;
}

[color-scheme='dark'] {
  .input-group-text {
    background-color: $darker;
    border-color: $dark;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 9999; /* ensure it's on top of other content */
}

.loading-box {
  padding: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>

<template>
  <div class="bg-3 flex h-screen justify-center items-center">
    <div class="clearfix">
      <h1 class="text-5xl font-light float-start display-3 me-4">404</h1>
      <div class="flex flex-col gap-2">
        <h4 class="text-xl font-medium pt-3">{{ $t('Oops! You are lost.') }}</h4>
        <p class="float-start text-color2">{{ $t('The page you are looking for was not found.') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage404'
}

</script>

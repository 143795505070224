import { createWebHistory, createRouter } from 'vue-router'

import Login from '@/pages/Login.vue'
import Home from '@/pages/Home.vue'
import sso from '@/pages/Sso.vue'
import page404 from '@/pages/Page404.vue'

const routes = [
  { path: '/', name: 'login', component: Login},
  { path: '/home', name: 'home', component: Home},
  { path: '/sso', name: 'sso', component: sso},
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: page404,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
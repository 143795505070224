<template>
  <div class="flex flex-col h-screen items-center justify-center gap-4">
    <div class="flex flex-col gap-2 mb-4">
      <img alt="Sodira logo" src="@/assets/sodira-logo.png" class="max-w-60">
      <h2 class="text-2xl text-center">{{ $t('Hello') }} {{ $store.state.auth.name }}</h2>
    </div>
    <div v-if="NxAccess || isSuperAdmin" class="group flex justify-center gap-4 items-center border border-solid border-slate-200 p-4 lg:p-8 w-2/3 md:w-1/3 lg:w-1/4 min-w-52 rounded-lg hover:border-slate-900 hover:cursor-pointer"
    @click="redirect(nxmanager_pathname)">
      <img alt="NxManager logo" src="@/assets/nxmanager-logo.png" class="w-16 sm:w-20">
      <span class="text-2xl">
        <span style="color: #10a4c3;">Nx</span>Manager
        <div class="ml-3 text-2xl text-slate-700 group-hover:ml-5 transition-all duration-300">
          <i class="fi fi-rr-arrow-alt-to-right text-lg"></i>
        </div>
      </span>
    </div>
    <div v-if="LuxAccess || isSuperAdmin" class="group flex justify-center gap-4 items-center border border-solid border-slate-200 p-4 lg:p-8 w-2/3 md:w-1/3 lg:w-1/4 min-w-52 rounded-lg hover:border-slate-900 hover:cursor-pointer"
    @click="redirect(luxmanager_pathname)">
      <img alt="LuxManager logo" src="@/assets/luxmanager-logo.png" class="w-16 sm:w-20">
      <span class="text-2xl">
        <span style="color: #565294;">Lux</span>Manager
        <div class="ml-3 text-2xl text-slate-700 group-hover:ml-5 transition-all duration-300 font-mono">
          <i class="fi fi-rr-arrow-alt-to-right text-lg"></i>
        </div>
      </span>
    </div>
    <button class="rounded-md px-1.5 py-2 transition-colors uppercase hover:bg-red-400 hover:bg-opacity-10" style="color: rgb(245, 0, 87);"
    @click="redirect(logout_pathname)">
      {{ $t('logout') }}
    </button>
  </div>
</template>

<script>
import router from '@/router';
import store from '@/store';

export default {
  name: 'HomePage',
  mounted() {
    if(!store.state.auth.authenticated) router.push('/')
    else {
      this.getUserAccess()
      this.routeUser()
    }
  },
  data() {
    return {
      isSuperAdmin: false,
      NxAccess: false,
      LuxAccess: false,
      luxmanager_pathname: '/luxmanager',
      nxmanager_pathname: '/nxmanager',
      logout_pathname: '/authentication/logout'
    }
  },
  methods: {
    routeUser() {
      if(!this.isSuperAdmin && (this.NxAccess != this.LuxAccess)) {
        window.location.pathname = this.NxAccess ? this.nxmanager_pathname : this.luxmanager_pathname
      }
    },

    getUserAccess() {
      store.state.auth.groups.forEach(group => {
        const [app, role] = group.split('_')
        if(role === 'superadmin') this.isSuperAdmin = true
        if(app === 'nxmanager') this.NxAccess = true
        else if(app === 'luxmanager') this.LuxAccess = true
      })
    },

    redirect(pathname) {
      window.location.pathname = pathname
    }
  }
}

</script>

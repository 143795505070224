<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  watch: {
    '$i18n.locale'() {
      this.updateTitle();
    }
  },
  mounted() {
    this.updateTitle(this.$i18n.locale);
  },
  methods: {
    updateTitle() {
      document.title = this.$t('Portal - Sodira');
    }
  }
}

</script>

<style lang="scss">
@import 'styles/style';
</style>
export function generateCodeVerifier() {
    const array = new Uint32Array(56 / 2); // 56 octets est une bonne longueur pour le code_verifier
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substring(-2)).join('');
  }

// Encoder en base64URL
export function base64UrlEncode(buffer) {
    return window.btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

// Générer un code_challenge à partir du code_verifier
export async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return base64UrlEncode(hash);
}
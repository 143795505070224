<template>
  <div class="flex justify-center h-screen items-center">
    <div class="border-gray-300 h-14 w-14 animate-spin rounded-full border-2 border-t-blue-600" />
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  mounted() {
    this.$store.commit('sso/login')
  },
}

</script>

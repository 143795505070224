import { createApp } from 'vue'
import store from '@/store'
import router from '@/router'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import en from "@/locales/en.json"
import fr from "@/locales/fr.json"
import '@flaticon/flaticon-uicons/css/all/all.css';  // Importation du CSS des icônes

const browserLanguage = navigator.language.split('-')[0]; // 'en-US' -> 'en'

const i18n = createI18n({
  locale: browserLanguage,
  fallbackLocale: "en",
  messages: { fr, en },
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)

app.mount('#app')